<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Transfers</h5>
				</a-col>
<!--				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">-->
<!--					<a-radio-group v-model="authorsHeaderBtns" size="small">-->
<!--						<a-radio-button value="all">ALL</a-radio-button>-->
<!--						<a-radio-button value="online">ONLINE</a-radio-button>-->
<!--					</a-radio-group>-->
<!--				</a-col>-->
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="false">

      <template slot="name" slot-scope="name">
				<div class="table-avatar-info">
					<div class="avatar-info">
						<h6>{{ name }}</h6>
					</div>
				</div>
			</template>

			<template slot="bank" slot-scope="bank">
				<div class="author-info">
					<h6 class="m-0">{{ bank }}</h6>
				</div>
			</template>


			<template slot="otp" slot-scope="otp">
				<a-tag class="tag-status" :class="otp ? 'ant-tag-primary' : 'ant-tag-muted'">
					{{ otp ? "CREDIT" : "DEBIT" }}
				</a-tag>
			</template>
      <template slot="confirmed" slot-scope="confirmed">
        <a-tag class="tag-status" :class="confirmed ? 'ant-tag-primary' : 'ant-tag-muted'">
          {{ confirmed ? "COMPLETED" : "PENDING" }}
        </a-tag>
      </template>
      <template slot="date" slot-scope="date">
        <div class="author-info">
          <h6 class="m-0">{{ date | formatDate }}</h6>
        </div>
      </template>

      <template slot="amount" slot-scope="amount">
        <div class="tag-status">
          <h6 class="m-0">${{ amount.toLocaleString() }}</h6>
        </div>
      </template>


		</a-table>
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
			}
		},
	})

</script>